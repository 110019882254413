/* Googlefont Poppins CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.sidebar {
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 280px;
  background: #fff;
  transition: all 0.5s ease;
  padding: 0px 7px;
}

.sidebar.active {
  width: 60px;
  overflow-x: hidden;
}

.sidebar .logo-details {
  height: 80px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .logo-details i {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.sidebar .nav-links {
  margin-top: 10px;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
}

.sidebar .nav-links li a {
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  color: #97a3b6;
  font-size: 20px;
  /* border-radius: 7px; */
}

.sidebar .nav-links li a {
  color: #97a3b6;
  font-size: 20px;

  /* Default black text */
}

.sidebar .nav-links li a.active {
  border-radius: 8px;
  background: #287f71;
  color: white !important;
}
.sidebar .nav-links li a.active {
  color: white !important;
}
.sidebar .nav-links li a.active:hover {
  background: #287f71;
  color: #f8f8f8 !important;
}

.activehover:hover {
  color: #fff !important;
}

.sidebar .nav-links .activehover:hover {
  color: rgb(8, 8, 8) !important;
}

.sidebar .nav-links .activehover.active {
  color: #fff !important;
}

.sidebar .nav-links .homeIcons {
  color: #97a3b6 !important;
}

.sidebar .nav-links li a.active i {
  color: #ffffff !important;
}

.sidebar .nav-links li a:hover {
  background: #9ee0d4;
}

.sidebar .nav-links li a:hover {
  background: #9ee0d4;
  /* Hover background */
  color: #303030 !important;
  /* Ensure hover doesn't override default text color */
}

.sidebar .nav-links li i {
  min-width: 47px;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  color: #303030;
  /* Default color (black) */
}

.submenu li a span {
  color: #303030;
}

.sidebar .nav-links li.active i {
  color: #ffffff !important;
}

.nav-links li .active i:hover {
  color: #000000 !important;
  /* Black color when hovered */
}

.nav-links li i {
  color: #97a3b6;
  /* Default color */
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.sidebar .nav-links li i.active {
  min-width: 60px;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  color: white;
}

.sidebar .nav-links li a .links_name {
  /* color: #97a3b6; */
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  padding: 1px 6px;
}

.sidebar .nav-links .log_out {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Logo-text {
  color: #fff;
  font-size: 22px;
  white-space: nowrap;
}

#header-button {
  border: 1px solid #a1a1a1;
}

#page-header {
  background-color: #ededed !important;
  padding: 0 20px;
  height: 62px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.top-bar-heading {
  padding-top: 15px;
  font-size: 20px;
}

.home-section {
  position: relative;
  background: #faf8f8;
  min-height: 100vh;
  width: calc(100% - 280px);
  left: 280px;
  transition: all 0.5s ease;
}

.sidebar.active ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
  padding: 0px;
}

.home-section nav {
  height: 60px;
  background: #fff !important;
  align-items: center;
  position: fixed;
  width: calc(100% - 280px);
  left: 280px;
  z-index: 100;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.sidebar.active ~ .home-section nav {
  left: 60px;
  width: calc(100% - 60px);
}

.home-section nav .sidebar-button {
  display: flex;
  color: #97a3b6;
  font-weight: 400 !important;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}

.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}

nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}

nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697ff;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}

.home-section nav .profile-details {
  display: flex;
  align-items: center;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 2px;
}

nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}

nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}

nav .profile-details i {
  font-size: 25px;
  color: #333;
}

.home-section .home-content {
  position: relative;
  padding-top: 60px;
}

/* Responsive Media Query */
@media (max-width: 1240px) {
  /* .sidebar {
    width: 60px;
  } */
  .sidebar .logo-details {
    width: 50px;
  }

  .sidebar.active {
    width: 220px;
  }

  .home-section {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ .home-section {
    overflow: hidden;
    left: 220px;
  }

  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ .home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1150px) {
  .home-content .sales-boxes {
    flex-direction: column;
  }

  .home-content .sales-boxes .box {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }

  .home-content .sales-boxes .top-sales {
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .overview-boxes .box {
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}

@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }

  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }

  .home-content .sales-boxes .sales-details {
    width: 560px;
  }
}

@media (max-width: 550px) {
  .overview-boxes .box {
    width: 100%;
    margin-bottom: 15px;
  }

  .sidebar.active ~ .home-section nav .profile-details {
    display: none;
  }
}

@media (max-width: 500px) {
  .sidebar {
    width: 0;
  }

  /* .sidebar.active {
    width: 25%;
  } */
  .sidebar .logo-details {
    width: 50px;
  }

  .home-section {
    width: 100%;
    left: 0;
  }

  .sidebar.active ~ .home-section {
    left: 60px;
    width: calc(100% - 60px);
  }

  .home-section nav {
    width: 100%;
    left: 0;
  }

  .sidebar.active ~ .home-section nav {
    left: 60px;
    width: calc(100% - 60px);
  }
}

.sidebar ul {
  padding-left: 0rem !important;
}

.center .dropdown-toggle::after {
  display: none;
}

/* ------------ Scrollbar ----------- */

/* Horizontal Scrollbar */
.sidebar::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  color: white;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6e6e6;
  border-radius: 10px;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #f8f7f7;
  border-radius: 10px;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #f8f7f7;
}

/* Hide vertical scrollbar */
.sidebar::-webkit-scrollbar-vertical {
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb-vertical:hover {
  background: #f7f4f4;
}

/* WebKit-based browsers (Chrome, Edge, Safari) */
.sidebar::-webkit-scrollbar {
  width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
}

.sidebar::-webkit-scrollbar-track {
  background: #e6e6e6; /* Track color */
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #f8f7f7; /* Handle color */
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #dcdcdc; /* Handle hover color */
}

/* Hide vertical scrollbar */
.sidebar::-webkit-scrollbar:vertical {
  display: none;
}

.sidebar::-webkit-scrollbar-thumb:vertical:hover {
  background: #f7f4f4;
}

.btn-button2 {
  color: #fff;
  padding: 0.5rem 0.9rem;
  font-weight: 600;
  background-color: #d86300;
  border-color: #d86300;
}

.btn-button2 i {
  font-weight: 600;
}

.btn-button2:hover {
  color: #fff;
  background-color: #d86300;
  border-color: #d86300;
}

.btn-button3 {
  color: #fff;
  padding: 0.5rem 0.9rem;
  font-weight: 600;
  background-color: #d86300;
  border-color: #d86300;
  width: 118% !important;
}

.btn-button3 i {
  font-weight: 600;
}

.btn-button3:hover {
  color: #fff;
  background-color: #d86300;
  border-color: #d86300;
}

.btn-button {
  color: #fff;
  padding: 0.3rem 0.6rem;
  font-weight: 400;
  background-color: #287f71;
  border-color: #287f71;
}

.btn-button i {
  font-weight: 600;
}

.btn-button:hover {
  color: #fff;
  background-color: #287f71;
  border-color: #287f71;
}

.btn-check:focus + .btn-button,
.btn-button:focus {
  color: #fff;
  background-color: #287f71;
  border-color: #287f71;
  box-shadow: 0 0 0 0.25rem #53c2af;
}

.btn-check:checked + .btn-button,
.btn-check:active + .btn-button,
.btn-button:active,
.btn-button.active,
.show > .btn-button.dropdown-toggle {
  color: #fff;
  background-color: #287f71;
  border-color: #287f71;
}

.btn-check:checked + .btn-button:focus,
.btn-check:active + .btn-button:focus,
.btn-button:active:focus,
.btn-button.active:focus,
.show > .btn-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #fa010182;
}

.btn-button:disabled,
.btn-button.disabled {
  color: #fff;
  background-color: #ff3f3f;
  border-color: #ff3f3f;
}

.btn-button-check:checked + .btn-button,
.btn-button.active,
.btn-button.show,
.btn-button:first-child:active,
:not(.btn-button-check) + .btn-button:active {
  background-color: #287f71;
  border-color: #287f71;
  color: #fff;
}

.btn-border {
  padding: 0.3rem 0.6rem;
  border: 1px solid #424242;
}

.btn-border:hover {
  border: 1px solid #424242;
  background-color: #cac8c8;
}

table.dataTable thead > tr {
  white-space: nowrap;
}

.btn-normal:hover {
  background-color: #e7e5e5 !important;
  border-radius: 0px;
}

.headColor {
  color: #287f71;
  font-weight: 400 !important;
  font-size: 19px;
  margin-bottom: 0px;
}

/* MultiStepFrom  */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #499b8d !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #287f71 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #287f71 !important;
}

/* thead tr th {
  font-size: 14px !important;
  font-weight: 600 !important;
} */

tbody tr td {
  font-size: 14px !important;
  font-weight: 500 !important;
}

tbody tr th {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.links_names {
  font-size: 14px !important;
}
.sidebar .nav-links li span .active {
  color: #287f71 !important;
}

a:hover {
  color: #424242;
}

/* .badges-Green {
  background-color: #668d47 !important;
  font-weight: 500;
  border-radius: 3px;
} */

.badges-Orange {
  background-color: #e37343 !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-Yellow {
  background-color: #ffba32 !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-Red {
  background-color: #f94c4c !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-Brown {
  background-color: #ae4f3a !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-LightGreen {
  background-color: #46b28b !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-Ash {
  background-color: #787878 !important;
  font-weight: 500;
  border-radius: 3px;
}

.badges-Blue {
  background-color: #155387 !important;
  font-weight: 500;
  border-radius: 3px;
}

.on-off-toggle {
  width: 40px;
  height: 20px;
  position: relative;
  display: inline-block;
}

.on-off-toggle__slider {
  width: 40px;
  height: 20px;
  display: block;
  border-radius: 10px;
  /* background-color: #f72a2a; */
  background-color: #eb862a;
  transition: background-color 0.4s;

  &:before {
    content: "";
    display: block;
    background-color: #fff;
    color: #fff;
    box-shadow: 0 0 0 1px #949494;
    bottom: 3px;
    height: 14px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
    width: 14px;
    z-index: 5;
    border-radius: 100%;
  }

  &:after {
    display: block;
    line-height: 20px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    content: "\2716";
    color: #ffffff;
    padding-left: 23px;
    transition: all 0.4s;
    content: "X";
  }
}

.on-off-toggle__input {
  position: absolute;
  opacity: 0;
}

.on-off-toggle__input:checked + .on-off-toggle__slider {
  /* background-color: #64e455; */
  background-color: #287f71;

  &:before {
    transform: translateX(20px);
    color: white !important;
  }

  &:after {
    content: "\2714";
    color: #ffffff !important;
    padding-left: 5px;
    content: "O";
  }
}

.on-off-toggle__input + .on-off-toggle__slider {
  position: absolute;
  top: 0;
  left: 0;
}

.plans {
  display: flex;
  justify-content: space-between;
  max-width: 970px;
  padding: 25px 5px;
  align-items: center;
  flex-wrap: wrap;
}

.plans .plan input[type="radio"] {
  display: none;
  /* Hide the actual radio inputs */
}

.plans .plan {
  cursor: pointer;
  width: calc(25% - 10px);
  /* Adjusted width for four cards in a row */
  margin-bottom: 20px;
  /* Adjusted margin for better spacing */
}

.plans .plan-content {
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  transition: box-shadow 0.4s;
  position: relative;
  display: flex;
  align-items: center;
}

.plans .plan-content img {
  margin-right: 30px;
  height: 30px;
}

.plans .plan-details span {
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
}

.plans .plan-content:hover {
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  border: 2px solid #287f71;
  background: #a7f0e4;
  transition: ease-in 0.3s;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #fff;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  box-shadow: 0px 0px 0px 4px #e7e4e4;
  box-shadow: 0px 0px 0px 4px #eb862a;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
    margin-bottom: 20px;
    /* Adjusted margin for better spacing */
  }
}

.submenu {
  background-color: #71d2c1;
  /* border-radius: 5px; */
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
/* 
.font-styles {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
} */

.font-styles {
  font-family: 'Outfit', system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}


@media screen and (max-width: 320px) {
  .hero_heading{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    font-size: clamp(21px, 4vw, 46px);
  }
  }

/* Top Bar  */
.social-icons {
  background-color: red;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: 7px;
  border-radius: 3px;
  font-size: small;
  transition: transform 0.3s ease-in-out;
}

.social-icons:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 576px) {
  .center-align-content {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
}

/* Header  */

.navMenu {
  background-color: red;
  border-radius: 20px;
  color: #fff;
  padding-left: 35px;
  padding-right: 30px !important;
  border-top: 6px solid black;
  border-right: 6px solid black;
}

.navWrapper {
  width: 80%;
}

.course-icons {
  background-color: #eb862a;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.clientNavBar .dropdown-menu {
  padding: 10px 30px;
}
.header-dropdown-menu {
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-weight: 500;
}

.header-dropdown-menu:hover {
  padding: 15px;
  background-color: #ffde00;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffde00;
  color: #000;
}

.donateBtnHeader {
  background-color: #ffde00;
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 600;
  margin-left: 40px;
  border-top: 6px solid black;
  border-right: 6px solid black;
}

.donateBtnHeader:hover {
  background-color: #eb862a;
}

.navMenu .nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px !important;
}

.navMenu .nav-link:hover {
  background-color: #ffde00;
  border-radius: 5px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  background-color: #ffde00;
  color: #fff;
  /* border-top-right-radius: 15px;
  border-bottom-right-radius: 15px; */
  border-radius: 10px;
}

/* Adding hover effect for dropdown items */
.navbar-nav .dropdown-menu .dropdown-item:hover,
.navbar-nav .dropdown-menu .dropdown-item:focus {
  background-color: #ffde00;
  color: #fff;
  border-radius: 5px;
}

/* Adding active effect for dropdown items */
.navbar-nav .dropdown-menu .dropdown-item.active {
  background-color: #ffde00 !important;
  color: #fff !important;
  border-radius: 5px;
}

/* Footer  */

.footer {
  background-color: #dab25a;
}

.footer .form-control {
  padding: 0.7rem 0.75rem;
}

.btn-danger-button {
  background-color: #eb862a !important;
  color: white;
  border: none;
  padding: 9px 20px;
  text-align: center;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn-outline-danger-button {
  color: #287f71;
  padding: 9px 20px;
  text-align: center;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn-outline-danger-button:hover {
  background-color: #287f71 !important;
  color: #fff;
}

.btn-danger-button:hover {
  background-color: #287f71 !important;
}

/* Dropdown active styling */
.bgActive {
  background-color: #ffde00 !important;
  color: #fff !important;
  border-radius: 5px;
}


/* Home  */

/* / Hero  / */

.paper-draw {
  max-width: 100%;
  height: auto;
  transform-origin: 83.98% 88.41%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  display: flex;
}

.heroSection .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.heroSection .heroPicture {
  background: radial-gradient(
    rgba(255, 255, 255, 0.5) 0%,
    rgba(247, 241, 190, 1) 100%
  );
}

@media (max-width: 768px) {
  .heroSection .heroPicture h1 {
    top: 40%;
  }
}

.whyArty {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
}

.whyArty .paint {
  background: linear-gradient(to right, transparent 50%, #fffdec 50%);
}

.whyArty .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 50px;
  color: #eb862a;
}

.whyArty .learnMoreBtn {
  border-radius: 3px;
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 778px) {
  .whyArty .paint {
    background: none;
    align-items: center !important;
    margin-bottom: 20px;
  }
}

/* Free Assessment  */

.freeAssessment .artyAnimatedText {
  margin-left: 20px;
  font-size: 10vw;
}

/* Courses  */

.bgimage {
  /* background-image: url("../assets/clientimage/IMG_195.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgchimage {
  /* background-image: url("../assets/clientimage/chinese_image.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.remove-padding > * {
  padding-left: 0px;
  padding-right: 0px;
}
.start {
  overflow-x: hidden;
}
.firsthead {
  color: white;
  background-color: rgba(235, 196, 90, 0.685);
  height: 100%;
}

.headcolor {
  color: #565454;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}

.headbody {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.form-font {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

/* Teachers  */

.tab .nav-link {
  color: black;
  background-color: white;
  border-right: 1px solid rgb(235, 235, 235);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tab .nav-link:hover {
  color: red;
}

.tab .nav-link.active {
  color: red;
  border-bottom: 2px solid red;
}

.nav-tabs {
  border-width: 0px !important;
  align-items: center;
  justify-content: center;
}

/* / About Us / */

.about_us {
  overflow-x: hidden;
}

.about {
  color: #fff;
  /* / background: -webkit-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%), -webkit-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%); /
  / background: -o-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%), -o-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%); /
  / background: -moz-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%), -moz-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%); / */
  background-color: #dab25a;
}

.about2 {
  background-image: url("../assets/clientimage/aboutBackground.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.backgound-imag-2 {
  background-image: url("../assets/clientimage/aboutBackground2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.image_border {
  background-color: black;
  border: 10px dashed red;
  border-radius: 30px;
  width: 90%;
  border-radius: 30px;
}

.bgimage_aboutus {
  background-color: #ecd8aa;
}

.bg {
  background-color: black;
  width: 80px;
  height: 30px;
}

/* Support  */

.support {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
}

.support .paint {
  background: linear-gradient(to right, transparent 50%, #dab25a 50%);
}

@media screen and (max-width: 778px) {
  .support .paint {
    background: none;
    align-items: center !important;
    margin-bottom: 20px;
  }
}

/* / News Update / */

.news {
  background-color: #f9fafb;
}
.news p {
  color: #374751;
  font-size: 20px;
}

.news .calender button {
  background-color: #eb862a;
  color: white;
  border: none;
  padding: 12px 22px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}
.news .calender .card-body h6 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* / Calender / */

.newsupdate {
  background-color: #f9fafb;
}
.newsupdate .heading {
  font-size: 50px;
  font-weight: bolder;
}
.newsupdate .container ul {
  margin-left: 0px;
}
.newsupdate .container ul li {
  text-decoration: none;
  list-style-type: none;
  margin-right: 5px;
  padding: 15px;
}

.newsupdate .comment p {
  font-size: 20px;
  color: rgb(73, 70, 70);
  margin-bottom: 10px;
}
.newsupdate .comment button {
  background-color: #eb862a;
  color: white;
  border: none;
  padding: 14px 24px;
  text-align: center;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .comment button {
    width: 100%;
  }
}
.form-check-input:checked {
  background-color: #eb862a;
  border-color: #eb862a;
}
.form input:hover {
  border-color: #eb862a;
}
.form textarea:hover {
  border-color: #eb862a;
}

/* / Contact / */

.contact {
  background-color: #f9fafb;
}
.contact h5 {
  font-size: 40px;
}
.contact .card {
  border: none;
  box-shadow: 2px 2px 4px #eeeeee, -2px -2px 4px #eeeeee;
  background-color: #f9fafb;
}
.contact form input {
  border-color: rgb(175, 175, 175);
  border-radius: none;
  background-color: #f9fafb;
}

/* input.form-control:hover {
  border-color: #eb862a !important;
} */
.contact form button {
  background-color: #eb862a !important;
  color: white;
  border: none;
  padding: 12px 20px;
  text-align: center;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .contact button {
    width: 100%;
  }
}
.contact ul li {
  max-width: 100%;
  display: flex;
  text-align: left;
  text-decoration: none;
  list-style-type: none;
  padding: 10px;
}
.contact p {
  font-size: 20px;
  font-weight: bold;
  color: #374751;
}

.image-in-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}

.image-in-text {
  position: relative;
  color: transparent;
  background-image: url("../assets/clientimage/bg-overlay-img.jpg");
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
}

.BOOKaFREE {
  font-size: 255% !important;
  font-family: "Barlow Semi Condensed", sans-serif !important;
}

@media (max-width: 975px) {
  .LEARNING {
    font-size: 230% !important;
  }
}

@media (max-width: 768px) {
  .LEARNING {
    font-size: 250% !important;
  }
}

@media (max-width: 280px) {
  .LEARNING {
    font-size: 205% !important;
  }
}

/* / Carousel  / */
.parent-testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto; 
  min-height: 300px; 
  padding: 20px; 
  box-sizing: border-box;
  word-wrap: break-word; 
  overflow: hidden; 
}

.paraSize {
  font-size: 16px; 
  line-height: 1.5; 
  white-space: pre-wrap; 
}

@media (max-width: 768px) {
  .paraSize {
    font-size: 14px; 
  }
}

@media (max-width: 576px) {
  .paraSize {
    font-size: 12px; 
  }
}

.carousel-container .react-multi-carousel-item {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: auto; 
  padding: 10px; 
}

.slidePaddings {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.paraSize {
  max-height: 150px; 
  overflow-y: auto; 
  scrollbar-width: thin;
  scrollbar-color: #dab25a transparent; 
}

.paraSize::-webkit-scrollbar {
  width: 5px;
}

.paraSize::-webkit-scrollbar-thumb {
  background-color: #dab25a; 
  border-radius: 5px;
}

.carousel_container {
  background-color: #dab25a !important;
  background: radial-gradient(
    circle,
    #ffffffba 15%,
    #ffffff8f 45%,
    #dab25a 100%
  );
  overflow: hidden;
  background-position: center;
  background-size: cover;
  padding: 0 !important;
  margin: 0 !important;
}

/* .carousel-container {
  padding: 0 !important;
  margin: 0 !important;
  max-height: 500px; 
  overflow: hidden;
  text-wrap: nowrap;
}

.parent-testimonial {
  max-height: 500px; 
  overflow: hidden;
} */

.imgWidth {
  width: 80%;
}

.paraSize {
  font-size: 17px !important;
}

.carousel-container {
  padding: 0 !important; 
  margin: 0 !important; 
}

.slidePaddings {
  padding: 0 !important; 
}

.paraSize {
  font-size: 17px !important;
}

/* Responsive font sizes */

@media (max-width: 768px) {
  .slidePaddings {
    padding: 40px 0px !important;
  }
}

@media (max-width: 576px) {
  .slidePaddings {
    padding: 10px 0px !important;
  }
}
@media (max-width: 768px) {
  .carousel-container {
    padding: 0 !important;
    margin: 0 !important;
    max-height: 1200px; 
    overflow: hidden;
    text-wrap: nowrap;
  }
  
  .parent-testimonial {
    max-height: 1200px; 
    overflow: hidden;
  }
}
.carousel-container {
  padding: 10px 30px;
}

.image-in-text span {
  color: transparent;
}

.LearnMore {
  background: white;
  color: red;
  border-radius: 3px;
  border: 2px solid rgba(255, 0, 0, 0.611);
  padding: 1% 3%;
  transition: background 0.5s, color 0.5s;
}

.LearnMore:hover {
  background: red;
  color: white;
}
.ShadowLayer {
  border-top: 10px solid #000;
  border-right: 10px solid #000;
  border-radius: 20px;
  background-color: #fffdec;
}

.react-multi-carousel-item{
    flex: 1 1 auto;
    position: relative;
    width: 917px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  color: red !important;
  cursor: pointer !important;
}

.react-multiple-carousel__arrow--left::before {
  content: "\e824";
  color: red !important;
  font-size: 45px !important;
}

.react-multiple-carousel__arrow--right::before {
  content: "\e824";
  color: red !important;
  font-size: 45px !important;
}
.topbar-wordpress {
  background-color: #343a40;
  border: 1px solid #6c757d;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.edit-container {
  position: relative;
  display: inline-block;
  width: 100%;
}


/* Teachers  */

@media (max-width: 483px) {
  .tab {
    display: flex;
    flex-direction: column; 
    align-items: center;
  }

  .tab .nav-link {
    flex: 1; 
    text-align: center;
    margin-bottom: 10px;
  }
}

.img-container {
  padding: 20px;  /* Add padding inside the container */
  margin: 10px auto; /* Add margin around the image */
  height: 140px; /* Adjust height for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
}


.heading {
  min-height: 50px; /* Ensure headings have equal height */
  display: flex;
  align-items: center;
  justify-content: center;
}
